<template>
  <b-form class="reporter-templates-edit">
    <template v-if="Object.keys(profile)">
      <page-head-actions
        v-model="profile.profile_definition_id"
        name="name"
        ref="name"
        v-validate="validations.name"
        :error="errors.first('name')"
        :placeholder="t('Profile Name')"
        :readOnly="isEditMode"
        editable
      >
        <template v-slot:buttons>
          <n-button
            type="primary"
            @click.native="onSave"
            class="btn-round pull-right"
            :visible_for_permissions='[{actions: ["POST"], path: "/profiles"}]'
            >{{ t("Save") }}</n-button
          >
          <b-button
            type="button"
            variant="primary"
            class="btn-round pull-right"
            @click="$router.go(-1)"
            >{{ t("Cancel") }}</b-button
          >
        </template>
      </page-head-actions>
      <b-row style="padding: 0">
        <b-col cols="12" class="row-eq-height">
          <b-card>
            <h5 class="title" style="display: flex;">
              <svgicon class="icon mr-2" icon="item-languages" width="17" height="17" />
              <span style="margin-top: 2px;">
                {{t('Languages')}}
              </span>
            </h5>
            <div>{{t('this section helps to manage language for the profile')}}</div>
            <b-container fluid style="padding: 0px">
              <b-row no-gutters>
                <b-col class="ml-auto" cols="2">
                  <n-button
                    type="primary"
                    size="md"
                    round
                    block
                    :disabled="!isAddLangEnabled"
                    @click.native="addLanguage"
                    :visible_for_permissions='[{actions: ["POST"], path: "/profiles"}]'
                  >
                    <i slot="label">
                      <svgicon class="icon" icon="icon-new" />
                    </i>
                    {{ t("New") }}
                  </n-button>
                </b-col>
              </b-row>
              <b-row>
                <el-table
                  :data="profile.profile_i18n"
                  ref="i18n_tableData"
                  style="width: 100%"
                >
                  <el-table-column
                    :label="t('Profile Language name')"
                    :min-width="100"
                  >
                    <template slot-scope="scope">
                      <fg-input
                        v-if="scope.$index === edit_lang_index || scope.row.new"
                        @input="setDirty"
                        v-model="scope.row.profile_name"
                        style="max-width: 175px; margin-bottom: 0;"
                      />
                      <span v-else style="margin-left: 10px">{{
                        t(scope.row.profile_name)
                      }}</span>
                    </template>
                  </el-table-column>
                  <el-table-column
                    :label="t('Profile language description')"
                    :min-width="100"
                  >
                    <template slot-scope="scope">
                      <fg-input
                        v-if="scope.$index === edit_lang_index || scope.row.new"
                        @input="setDirty"
                        v-model="scope.row.profile_description"
                        style="max-width: 175px; margin-bottom: 0;"
                      />
                      <span v-else style="margin-left: 10px">{{
                        t(scope.row.profile_description)
                      }}</span>
                    </template>
                  </el-table-column>
                  <el-table-column :label="t('Language')" :min-width="100">
                    <template slot-scope="scope">
                      <el-autocomplete
                        v-if="scope.row.new"
                        style="max-width: 175px"
                        :fetch-suggestions="getAvailableLanguages"
                        class="isp-profile__autocomplete"
                        popperClass="isp-profile__dropdown"
                        size="large"
                        :placeholder="t('Language')"
                        @input='onInputLang($event, scope.row)'
                        @select="onAutoCompleteLang($event, scope.row)"
                        v-model="scope.row.language_name">
                        <template slot-scope="{ item }">{{ item.name }}</template>
                      </el-autocomplete>
                      <el-autocomplete
                        v-if="scope.$index === edit_lang_index"
                        style="max-width: 175px"
                        :fetch-suggestions="getAvailableLanguages"
                        class="isp-profile__autocomplete"
                        popperClass="isp-profile__dropdown"
                        size="large"
                        placeholder="Select language"
                        @input='onInputLang($event, scope.row)'
                        @select="onAutoCompleteLang($event, scope.row)"
                        v-model="edit_lang_name">
                        <template slot-scope="{ item }">{{ item.name }}</template>
                      </el-autocomplete>
                      <span v-if="!scope.row.new && scope.$index !== edit_lang_index">
                        {{ getLanguageNameById(scope.row.language_id) }}
                      </span>
                    </template>
                  </el-table-column>
                  <el-table-column :label="t('Actions')" :width="150" fixed="right">
                    <template slot-scope="scope">
                      <n-button
                        :disabled='scope.row.new'
                        @click.native="handleLanguageEdit(scope.$index, scope.row)"
                        :visible_for_permissions='[{actions: ["POST"], path: "/profiles"}]'
                        class="m-1"
                        type="warning"
                        size="sm"
                        round
                        icon>
                        <i class="now-ui-icons business_badge" />
                      </n-button>
                      <n-button
                        @click.native="handleLanguageDelete(scope.$index)"
                        class="m-1"
                        type="danger"
                        :visible_for_permissions='[{actions: ["DELETE"], path: "/profiles/.*"}]'
                        size="sm"
                        round
                        icon>
                        <i class="now-ui-icons ui-1_simple-remove" />
                      </n-button>
                    </template>
                  </el-table-column>
                </el-table>
              </b-row>
            </b-container>
          </b-card>
        </b-col>
      </b-row>
      <el-collapse v-model="mutableExpandedServices">
        <div v-for="serviceName in hardCodedServicesNS" :key="serviceName">
          <Service :visible_for_services="serviceName"
            @onToggleCollapse="onToggleCollapse"
            :serviceName="serviceName"
            :isCollapsed="mutableExpandedServices.includes(serviceName)"
            :visible_for_permissions='[{actions: ["POST"], path: "/profiles"}, {actions: ["PUT"], path: "/profiles"}]'
            showCollapse
          ></Service>
        </div>
        <div v-for="serviceName in hardCodedServicesCB" :key="serviceName">
          <Service :visible_for_services="serviceName"
            @onToggleCollapse="onToggleCollapse"
            :serviceName="serviceName"
            :visible_for_permissions='[{actions: ["POST"], path: "/profiles"}, {actions: ["PUT"], path: "/profiles"}]'
          ></Service>
        </div>
      </el-collapse>
      <b-row style="padding: 0">
        <b-col :sm="12" class="row-eq-height">
          <b-card>
            <h5 class="title" style="display: flex;">
              <svgicon class="icon mr-2" icon="item-notification" width="17" height="17" />
              <span style="margin-top: 2px;">
                {{t('Notifications')}}
              </span>
            </h5>
            <b-container fluid style="padding: 0px">
              <p>{{t('this section helps to enable/disable the notifications of the services')}}</p>
              <b-row no-gutters>
                <b-col
                  :sm="12"
                  v-for="item in notificationOptionsArray"
                  :key="item.name"
                >
                  <b-container fluid style="padding: 0px" v-if="checkServicesAndProducts(item)">
                    <b-row no-gutters>
                      <b-col
                        lg="3"
                        sm="12"
                        class="form-group has-label pt-2"
                        align-self="center"
                      >
                        <h5
                          class="mx-auto my-auto"
                          style="cursor: pointer; white-space: nowrap"
                        >
                          {{ t(translateHandler(item.name, "_", " ")) }}
                        </h5>
                      </b-col>
                      <b-col lg="3" sm="12" align-self="center">
                        <n-switch
                          v-if="item.value"
                          v-model="item.value.send_notifications"
                          :disabled="!!item.requiredService && disabledServices.includes(item.requiredService)"
                          :visible_for_permissions='[{actions: ["POST"], path: "/profiles"}]'
                          :service="item.name"
                          @input="
                            toggleByServiceAndPropName(
                              $event,
                              item.name,
                              'send_notifications'
                            )
                          "
                          color="allot"
                        />
                      </b-col>
                    </b-row>
                  </b-container>
                </b-col>
              </b-row>
            </b-container>
          </b-card>
        </b-col>
      </b-row>
    </template>
  </b-form>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from "vuex";
import PageHeadActions from "@/components/PageHeadActions.vue";
import { ToggleList, Switch } from "@/components";
import General from "@/mixins/General";
import languagesList from "@/mixins/languagesList";
import authorization from "@/mixins/authorization"
import Service from "@/components/IspServices/Configuration/Profiles/Service";

export default {
  name: "ProfileEdit",
  components: {
    [ToggleList.name]: ToggleList,
    [Switch.name]: Switch,
    PageHeadActions,
    Service,
  },
  mixins: [General, languagesList, authorization],
  data() {
    return {
      isEditMode: false,
      edit_lang_index: '',
      edit_lang_name: '',
      mutableExpandedServices: [],
      profile: {
        profile_definition_id: '',
        profile_i18n: [],
        services_configuration: {},
      },
      // ! hardcoded
      hardCodedServicesNS: [
        "parental_control",
        "threat_protection",
        "auto_notice",
        "ads_free"
      ],
      hardCodedServicesCB: [
        "anti_bullying",
        "location_management"
      ],
      validations: {
        name: {
          required: true,
          max: 64,
          regex: /^[a-zA-Z0-9\\\\-\\\\._@\s]+$/,
        },
      },
    };
  },
  created() {
    this.setProfile();
  },
  methods: {
    ...mapActions("generalManagement/administrators", ["getAdministrator"]),
    ...mapActions("ispServices/profiles", [
      "createProfile",
      "getProfiles",
      "updateProfile",
    ]),
    ...mapActions('settings', ['setIsFormDirty']),
    ...mapMutations("ispServices/profiles", [
      "updateProfileProp",
      "updateProfileServiceProp",
    ]),
    async setProfile() {
      const profileId = this.$route.params.id;
      if (profileId) {
        this.isEditMode = true;
      } else if (!this.defaultAdministratorLanguageId) {
        await this.getAdministrator(this.managerId);
      }
      const profile = _.cloneDeep(this.$store.getters["ispServices/profiles/profile"]);
      Object.assign(this.profile, profile);
    },
    getError(fieldName) {
      return this.errors.first(fieldName);
    },
    async checkIfValid() {
      const isLastLangUnselected = !this.profile.profile_i18n[
        this.profile.profile_i18n.length - 1
      ].language_id;
      if (isLastLangUnselected) this.profile.profile_i18n.pop();

      return await this.$validator.validate();
    },
    async onSave() {
      if (!(await this.checkIfValid())) return;
      this.setIsFormDirty(false);
      let langs = _.cloneDeep(this.profile.profile_i18n);
      langs.forEach(language => {
        if (language.new) {
          delete language.new;
          delete language.language_name;
        }
      });

      this.updateProfileProp({
        propName: "profile_i18n",
        value: langs,
      });

      if (this.isEditMode) {
        this.updateProfile();
      } else {
        this.updateProfileProp({
          propName: "profile_definition_id",
          value: this.profile.profile_definition_id,
        });
        this.createProfile();
      }

      this.getProfiles();
      this.$router.push({ name: "ISP Services / Configuration / Profiles" });
    },
    toggleByServiceAndPropName(isEnabled, serviceName, propName) {
      this.updateProfileServiceProp({
        serviceName,
        propName,
        value: isEnabled,
      });
    },
    checkServicesAndProducts(item){
      return this.checkServices(item.requiredService) && this.checkProducts(item.requiredProduct)
    },
    addLanguage() {
      const langs = this.profile.profile_i18n;
      langs.push({
        language_id: "",
        language_name: "",
        profile_name: "",
        profile_description: "",
        new: true,
      });
      this.setDirty();
    },
    onAutoCompleteLang(language, row) {
      row.language_id = language.value;

      if (row.new) {
        row.language_name = language.name;
      } else {
        this.edit_lang_name = language.name;
      }

      this.setDirty();
    },
    onInputLang(value, row) {
      if (value === '') {
        row.language_id = '';
      }
    },
    handleLanguageDelete(index) {
      this.profile.profile_i18n.splice(index, 1);
      this.setDirty();
    },
    handleLanguageEdit(index, row) {
      this.edit_lang_index = index;
      this.edit_lang_name = this.getLanguageNameById(row.language_id);
      this.setDirty();
    },
    onToggleCollapse(serviceName, forceClose = false) {
      if (forceClose || this.mutableExpandedServices.includes(serviceName)) {
        this.mutableExpandedServices = this.mutableExpandedServices.filter(
          (srvcName) => srvcName !== serviceName
        );
      } else {
        this.mutableExpandedServices.push(serviceName);
      }
    },
    setDirty() {
      this.setIsFormDirty(true);
    },
    getAvailableLanguages(queryString, cb) {
      const query = queryString ? queryString.toLowerCase() : '';
      const usedLanguageIds = this.profile.profile_i18n.map(item => item.language_id.toLowerCase());
      const results = this.supported_languages_list.reduce((filteredLanguages, option) => {
        if ((option.language_name.toLowerCase().includes(query) || option.language_id.toLowerCase().includes(query))
          && !usedLanguageIds.includes(option.language_id.toLowerCase())
        ) {
          let filteredLanguage = {
            name: option.language_name,
            value: option.language_id
          };
          filteredLanguages.push(filteredLanguage);
        }
        return filteredLanguages;
      }, []);
      cb(results);
    },
  },
  computed: {
    ...mapGetters("ispServices/profiles", [
      "notificationOptions",
      "disabledServices",
    ]),
    ...mapGetters("settings", ["getSubSystemsServicesId"]),
    ...mapState("settings", ["isFormDirty"]),
    ...mapState("auth", ["managerId"]),
    ...mapState("generalManagement/administrators", [
      "defaultAdministratorLanguageId",
    ]),
    notificationOptionsArray(){
      let ret = []
      let isGeneral = false
      for (const [key, value] of Object.entries(this.notificationOptions)) {
        if ( key == "general"){
          isGeneral = true
          ret.push( { name: key, value: value, requiredProduct: ["CBEndpoint", "RouterSecure"]})
        }
        else if ( key == "router_protection" || key == "lan_protection"){
          ret.push( { name: key, value: value, requiredProduct: ["RouterSecure"]})
        }
        else
          ret.push( { name: key, value: value, requiredService: key})
      }
      let retOrdered = Array.from(ret).sort( (a,b) => {
          let aInMainServices = this.hardCodedServicesNS.includes(a.name)
          let bInMainServices = this.hardCodedServicesNS.includes(b.name)
          let aIsGeneral = a.name === 'general'
          let bIsGeneral = b.name === 'general'

          if (aIsGeneral)
            return 1;
          if ( bIsGeneral)
            return -1;

          if ( aInMainServices){
            if (bInMainServices)
              return a.name.toUpperCase() > b.name.toUpperCase() ? 1: -1;
            else
              return -1;
          }
          else{
            if (bInMainServices)
              return 1;
            else
              return a.name.toUpperCase() > b.name.toUpperCase() ? 1: -1;
          }
       });
      if ( !isGeneral)
        retOrdered.push({ name: "general", value: { send_notifications: false}, requiredProduct: ["CBEndpoint", "RouterSecure"]})
      return retOrdered
    },
    isAddLangEnabled() {
      if (!this.profile.profile_i18n) return;
      const exisitingLangsAmount = this.profile.profile_i18n.length;
      if (exisitingLangsAmount) {
        const lastLangObj = this.profile.profile_i18n[exisitingLangsAmount - 1];
        return Object.values(lastLangObj).every((prop) => prop);
      } else return true;
    },
  },
};
</script>
<style lang="scss" scoped>
.text-allot {
  color: #f96332 !important;
}
.separator {
  height: 1px;
  background-color: #e7e7e7;
  margin: 5px 20px;
  margin-bottom: 10px;
}
::v-deep .el-collapse-item__wrap {
  border-bottom: unset;
}
::v-deep .el-collapse-item__arrow {
  display: none;
}
::v-deep div[role="tab"] {
  display: none;
}

::v-deep .el-collapse-item__wrap {
  background-color: unset;
  border-bottom: unset;
}
::v-deep .el-collapse {
  border-top: unset;
  border-bottom: unset;
}
</style>
<style lang="scss">
  .isp-profile__dropdown {
    width: auto !important;
    min-width: 175px;
  }
  .isp-profile__autocomplete {
    .el-input__inner {
      border-radius: 50px;

      &:focus {
        border-color: #f78d1e;
      }
    }
  }
</style>
