<template>
  <b-card class="px-4 pb-0" v-if="make_visible">
    <template v-slot:header>
      <div class="d-flex align-items-center justify-content-between service__header">
        <h4 class="text-capitalize mb-0 title mr-4" style="display: flex;">
          <svgicon 
            class="icon mr-2"
            :icon="icons[serviceName] || 'item-passwordpolicy'"
            width="17"
            height="17"
            style="margin-top: 1px;"
            />
          <span>{{ t(translateHandler(serviceName, "_", " ")) }}</span>
        </h4>
        <div class="d-flex align-items-center">
          <div @click="onToggleCollaps" class="button d-flex" v-if="showCollapse">
            <n-button
              type="neutral"
              size="sm"
              block
              v-text="isCollapsed ? $t('Collapse - ') : $t('Expand + ')"
            />
          </div>
          <div class="d-flex ml-4">
            <p class="text-capitalize mr-3 mb-0">{{t('enabled')}}:</p>
            <n-switch
              class="mt-0 mb-0"
              v-model="isServiceEnabled"
              :service="serviceName"
              color="allot"
              :visible_for_permissions="visible_for_permissions"
            />
          </div>
        </div>
      </div>
    </template>
    <el-collapse-item :name="serviceName">
      <component v-show="currentComponent" v-bind:is="currentComponent" :visible_for_permissions="visible_for_permissions">
      </component>
    </el-collapse-item>
  </b-card>
</template>

<script>
import {mapState, mapMutations, mapActions} from "vuex";
import { Switch } from "@/components";
import General from "@/mixins/General";
import visible from '@/mixins/authorization'

export default {
  name: "Service",
  components: {
    [Switch.name]: Switch,
  },
  props: {
    serviceName: {
      type: String,
      required: true,
    },
    isCollapsed: {
      type: Boolean,
      default: true,
    },
    showCollapse: {
      type: Boolean,
      default: false,
    },
    visible_for_permissions: { 
      type: Array, 
      default: function () { return [] }
    }    
  },
  mixins: [General, visible],
  data() {
    return {
      icons: {
        ads_free: 'item-adsfree',
        anti_bullying: 'item-bullingprotection',
        general: 'item-general',
        location_management: 'item-locationmanagement',
        parental_control: 'item-contentfilter',
        threat_protection: 'item-threatprotection',
      },
    };
  },
  created() {},
  methods: {
    ...mapActions('settings', ['setIsFormDirty']),
    ...mapMutations("ispServices/profiles", ["setServiceAvailability"]),
    onToggleCollaps() {
      this.$emit("onToggleCollapse", this.serviceName);
    },
  },
  computed: {
    ...mapState("ispServices/profiles", ["profile"]),
    isServiceEnabled: {
      get() {
        const service = this.profile.services_configuration[
          this.serviceName
        ];
        if (!service) return;
        return service.enabled;
      },
      set(isEnabled) {
        if (!isEnabled) {
          const forceClosed = true;
          this.$emit("onToggleCollapse", this.serviceName, forceClosed);
        }
        this.setIsFormDirty(true);
        this.setServiceAvailability({
          serviceName: this.serviceName,
          isEnabled,
        });
      },
    },
    currentComponent() {
      if (!this.isCollapsed) return;
      switch (this.serviceName) {
        case "parental_control":
          return () =>
            import(
              /* webpackChunkName: "ParentalControlService" */ "./ParentalControlService"
            );

          break;
        case "threat_protection":
          return () =>
            import(
              /* webpackChunkName: "ThreatProtectionService" */ "./ThreatProtectionService"
            );

          break;
        case "auto_notice":
          return () =>
            import(
              /* webpackChunkName: "ThreatProtectionService" */ "./AutoNoticesService"
            );

          break;
        case "ads_free":
          return () =>
            import(
              /* webpackChunkName: "ThreatProtectionService" */ "./AdsFreeService"
            );

          break;
        default:
          break;
      }
    },
  },
  watch: {},
};
</script>
<style lang="scss" scoped>
.bottom-border {
  width: 100%;
  background-color: black;
}
.card {
  margin-bottom: 10px;
}
.card-header {
  padding-top: unset;
}
.card-body {
  padding: 0 15px;
  margin: 0;
}
.service-icon {
  position: relative;
  top: -4px;
}
.service__header {
  min-height: 50px;
}
</style>
