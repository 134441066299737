import { getLanguageCountryCodeList, PREVIOUSLY_SUPPORTED_LANGUAGES } from "@/util/languageUtils";

export default {
  computed: {
    supported_languages_list() {
      return getLanguageCountryCodeList();
    },
    previously_supported_languages() {
      return PREVIOUSLY_SUPPORTED_LANGUAGES;
    }
  },
  methods: {
    querySearchLanguage(queryString, cb) {
      const query = queryString ? queryString.toLowerCase() : '';
      const results = this.supported_languages_list.reduce((filteredLanguages, option) => {
        if (option.language_name.toLowerCase().includes(query) || option.language_id.toLowerCase().includes(query)) {
          let filteredLanguage = {
            name: option.language_name,
            value: option.language_id
          };
          filteredLanguages.push(filteredLanguage);
        }
        return filteredLanguages;
      }, []);
      cb(results);
    },
    getLanguageNameById(id) {
      const language = this.supported_languages_list.find(lang => lang.language_id.toLowerCase() === id.toLowerCase())
        || this.previously_supported_languages.find(lang => lang.language_id.toLowerCase() === id.toLowerCase())
      return (language && language.language_name);
    },
    isLanguageRtl(languageCode) {
      return ['ar-OM', 'he-IL'].includes(languageCode);
    }
  }
}
